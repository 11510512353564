
  
@media screen and (max-width: 998px) {

  /* .screen-size{
    display: none;
  } */

  .AppBig {
    text-align: center;
    height: 19vmin;
    display: none;
  }
  
  .AppSmall {
    text-align: center;
    height: 10vmin;
    display: block;
  }

  .Login{
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 50px;    
    animation-name: loginComponents;
    animation-duration: 1s;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    padding-top: 1rem;
    left: 0;
    width: 100%;
    height: 350px;
    background-color: rgba(11,40,66);
    z-index: 9999;
  }

  .carrosselhome{
    display: absolute;
    margin-top: 25%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 500;
  }
  
  .carrossel{
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 30vh;
  }

  .credenciadosmapa{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.mapamt{
  width: 9rem;
  height: 9rem;    
}

.listacredenciados{
  list-style: none;
  font-size: 0.9rem;
  font-weight: 400;
  color: #1A4066;
  font-family: "Poppins";
  margin-top: 1rem;
  margin-left: -2rem;
}

  .descanso{
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #969696;
    font-family: "Poppins";
    margin-left: 10px;
  }

  .descanso2{
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    font-weight: 900;
    color: #969696;
    font-family: "Poppins";
    margin-left: 10px;
  }

  .cardVantagens{
    display: flex;
    flex-direction: row;  
    align-items: center;
    text-align: left;
    width: 100%;
    height: 7rem;
    background-color: #FFF;  
    border: solid 1px #969696;
    border-radius: 5px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0.5em;
    padding: 0.5rem;
    line-height: 1rem;
  }

  .cardCredenciados{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 5rem;
    height: 5rem;
    background-color: #FFF;  
    border: solid 1px #969696;
    border-radius: 5px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem;
  }

  .handHelt{
    margin-top: 1rem;
    width: 8rem;
    height: 10rem;
    position: absolute;
  }

  .backgroundSeguros{
    width: 100%;
    height: 100%;
    background-color: #1CB1E2;
  }

  .segurofonta{
    font-size: 0.7rem;
    margin: 0;
  }
  
  .segurofontb{
    font-family: "Poppins-Bold";
    margin: 0;
    font-size: 0.7rem;
    font-weight: 900;  
  }

  .segurofontc{
    font-size: 0.4rem;
    margin: 0;
  }

 .segurostext{
    font-family: "Poppins";
    color: #fff;
    margin-top: 1rem;
    text-align: end;
    margin-right: 1rem;
    
}

.cardEmpresas{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 4rem;
  height: 3rem;
  background-color: #FFF;  
  border: solid 1px #969696;
  border-radius: 5px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.empresaVsisibleLG{
  display: none;
}

.empresaVsisibleSM{
  display: block;
}

.divEmpresasText{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: -0.5rem;
  font-family: "Poppins";
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: #969696;
  font-weight: 500;
}

.divEmpresas{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

  .cardsCredenciados{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
  }

  .cardVantagensImage{
    width: 60px;
    height: 60px;
    margin-top: 0px;
    margin-right: 0.5rem;
  }
  
  .cardCredenciadosImage{
    margin-top: 1rem;
    width: 50px;
    height: 50px;
  }
.cardCredenciadosTextTitle{
  font-family: "Poppins";
  font-size: 0.7rem;
  font-weight: 400;
  color: #1A4066;
}

  .cardVantagensTextTitle{
    font-size: 0.7rem;
    font-weight: 700;
    color: #1A4066;
    
  }

  .labelCotacao{
    font-family: "Poppins";
    font-Size: 0.8rem;
    color: #969696;
  }

  .inputCotacao{
    border-radius: 5px;
    border: none;
    height: 2rem;
    background-color: #D9D9D9;
    color: #1A4066;
  }

  .divCotacao{
    margin-left: 0.5rem;
    margin-right:0.5rem;
  }

  .btnCotacao{
    margin-top: 0.5rem;
    background-color: #1CB1E2;
    color: #fff;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 0.9rem;
    width: 20rem;
    height: 2.5rem;
    border-radius: 3px;
    border: none;
  }
  
  .cardVantagensTextDescription{
    margin-top: -10px;
    margin-bottom: -5px;
    font-size: 0.7rem;
    
    
  }

  .MenuLarge{
    display: none;
    z-index: 999;
  }

  .MenuSmall{
    display: block;
    z-index: 999;
  }
  

  .buttonsmallmenu{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: #f4f4f4;
    font-family: "Raleway";
    font-weight: 700;
    color: #FFF;
    font-size: 16px;
    border-color: #fff;
    border-style: solid;
    border-width: 2px;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .btopenmenu{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: #f4f4f4;
    font-family: "Raleway";
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    border-color: #fff;
    border-style: solid;
    border-width: 2px;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .buttonLink{
    font-family: "open sans";
    color: #031077;
    font-size: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    
  }

  .lineTriCollor{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 15px;
  }

  .assinatura{
    margin-top: 1rem  ;
    text-align: center;
    margin: auto;
    padding: 1.0rem;
  }

  .textAssinaturaItemTitulo{                
    font-family: "Poppins";
    font-size: 1rem;
    color: #1A4066;
    font-weight: 900;
    margin-left: 1rem;
    text-align: left;
  }

  .textAssinaturaItemDescricao{                
    font-family: "Poppins";
    font-size: 14px;
    text-align: left;
    color: #969696;
    margin-left: 1rem;    
  }

  .footer-inf{
    display: flex;    
    justify-content: center;
    width: 100%;
    background-color: #1A4066;
  }

  .footer-info{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    background-color: #D9D9D9;
    font-family: 'Poppins-Light';
    color: #969696;
    font-size: 0.9rem;
    line-height: 1.5;    
    padding: 1rem;
    
  }
  td p{
    margin-top: -12px;
  }

  .table{
    margin-top: 2rem;
    
  }

  .btcomprarhome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  
  .table-footer{
    display: flex;
    justify-content: center;
    text-align: left;
    vertical-align: top;
    width: 100%;
    font-family: 'Poppins-Light';
    font-size: 0.8rem;
    color: #FFF;    
    margin-top: 2rem;
    margin-left: 0.5rem;
  }
  
  .title-table-footer{
    display: flex;
    justify-content: left;
    width: 100%;    
    font-family: 'Poppins-Bold';
    font-size: 0.8rem;
    color: #FFF;
    
  }

  .footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    line-height: 0.5;
    background-color: #1A4066;
    
    
  }

  .footer-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  

  .assinaturaFoto{
    display: none;
    
  }

  .textAssinaturaA{
    font-family: "Poppins-Bold";
    font-size: 1rem;
    letter-spacing: 0.1rem;
    color: #969696;
    font-weight: 900;
  }

  .textAssinaturaB{
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.1rem;
    color: #969696;
  }
  
    .header {
    display: flex;
    width: 100%;
    padding: 20px;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;    
  }
  
  .logo {
    padding: 0;    
  }

  .seja-credenciado-row {
    margin-top: 6rem;
  }

  .seja-credenciado-input { 
    padding: 0rem 2rem;
  }

  .seja-credenciado-btn { 
    width: 100%
  }

  .especialidade-hero-web { 
    display: none !important;
  }

  .especialidade-hero-web-image { 
    height: 1px !important;
  }

  .especialidade-hero-app { 
    display: block !important;
    margin-top: 70px;
  }

  .hero-app { 
    display: block !important;
    margin-top: 70px;
  }

  .app {
    display: block !important;
  }

  .web {
    display: none !important;
  }

  .hero-text-sobre-background {
    margin-top: -6px !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 20vh !important;
    background-color: #1A4066 !important;
    align-items: flex-start !important;
    justify-content: center !important;
    color: white !important;
    font-size: 20px !important;
    padding: 3rem 1rem 0rem 1rem !important;
    font-family: Poppins !important;
  }

  .hero-text-sobre { 
    text-align: "center";
    height: "70vh";
    vertical-align: "middle";
  }

  .sobre-text { 
    width: 90% !important;
    text-align: center !important;
    font-size: 1.3rem !important;
  }

  .credenciado-title {
    margin-top: 6.5rem !important;
  }

  .select-credenciado {
    margin: 1rem !important;
  }

  .select-credenciado-sm {
    margin: 1rem !important;
  }

  .select-credenciado-fundo {
    height: 280px !important;
  }

  .credenciado-result {
    width: 50% !important;
  }

  .credenciado-filtro {
    width: 86% !important;
  }

  .dashboard-cliente {
    margin-top: 10vh !important;
  }

  .stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
    margin-bottom: 3vh;
  }
  
  .circle {
    width: 44px;
    height: 35px;
    border-radius: 50%;
    border: 4px solid #999;
    background: #fff;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #999;
  }
  
  .circle.active {
    background: #fff;
    border-color: #29ade0;
    cursor: pointer;
    color: #000;
  }
  
  .line {
    height: 4px;
    width: 40px;
    background: #999;
    margin: -5px;
  }
  
  .line.active {
    background: #29ade0;
  }

  .step1bt{
    text-align: center; 
    display: flex;
    flex-direction: column; 
    flex-wrap: wrap; 
    justify-content: center;
    align-items: center;
  }
  
  .modalSeguroContainer{
    display: flex;
    justify-content: space-between;
    height: 80vh;
  }
  
  .modalSeguroFoto{
    display: none;
  }

  .modalSeguroText{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalSeguro{
    width: 100%;
    margin-top: 5vh;
    position: absolute;
    z-index: 9999;
    
  }
  
  .modal-backdrop {
    display: none !important;
  }

  .buttonSeguro{
    margin-bottom: 1rem;
    width: 100%;
    height: 3.5rem;
  }

  .btstep1{
    width: 100%;
    background-color: #29ade0;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
  }

  .btstep1:hover{
    background-color: #1c7daa;
  }

  .step3{
    padding: 0 2rem;
  }

  .contrato{
    width: 100%;
  }

}

  


