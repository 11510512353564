
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400&family=Raleway:ital,wght@0,100;0,200;0,400;0,600;0,700;0,900;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.tooltip-adm { 
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1990CF;
  color: white;
  font-weight: 'bold';
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
}

.tooltip-seguro { 
  position: absolute;
  top: 40%;
  left: 88%;
  transform: translateX(-50%);
  background-color: #313334;
  color: white;
  font-weight: 'bold';
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* ocultar o scroll somente no body */
body::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  
}

.custom-scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #888; /* Cor do thumb e do track */

}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Largura do scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Cor do preenchimento do thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Cor do preenchimento do thumb ao passar o mouse */
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-button {
  display: none;
}

.empresas{
  padding-left: 10%;
  padding-right: 10%;
}

.cardsEmpresas{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} 

.cardEmpresas{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 9rem;
  height: 7rem;
  background-color: #FFF;  
  border: solid 1px #969696;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 1rem;
}

.divEmpresas{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
}

.divEmpresasText{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  font-family: "Poppins";
  font-size: 2.2rem;
  letter-spacing: 0.1rem;
  color: #969696;
  font-weight: 500;
}

.empresaVsisibleLG{
  display: block;
}

.empresaVsisibleSM{
  display: none;
}

.contratecontainer{
  margin-left: 10%;
  margin-right: 10%;  
}

.contrate{
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;  
}

.cardCredenciados{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 8rem;
  height: 8rem;
  background-color: #FFF;  
  border: solid 1px #969696;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.segurofonta{
  font-size: 2rem;
  margin: 0;
}

.segurofontb{
  font-family: "Poppins-Bold";
  margin: 0;
  font-size: 2rem;
  font-weight: 900;  
}

.segurofontc{
  font-size: 1.5rem;
  margin: 0;
}

.segurostext{
  font-family: "Poppins";
  color: #fff;
  margin-top: 4rem;
}

.labelCotacao{
  font-family: "Poppins";
  font-Size: 1.1rem;
  color: #969696;
}

.divCotacao{
  margin-left: 2rem;
  margin-right: 2rem;
}

.inputCotacao{
  border-radius: 5px;
  border: none;
  height: 2.5rem;
  background-color: #D9D9D9;
  color: #1A4066;
}

.btnCotacao{
  margin-top: 0.5rem;
  background-color: #1CB1E2;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.2rem;
  width: 20rem;
  height: 2.5rem;
  border-radius: 3px;
  border: none;
}

.cardCredenciadosImage{
  margin-top: 1rem;
  width: 70px;
  height: 70px;
}
.cardCredenciadosTextTitle{
font-family: "Poppins";
font-size: 1rem;
font-weight: 400;
color: #1A4066;
}

.cardsVanatgens{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}

.handHelt{
  margin-top: 1rem;
  width: 25rem;
  height: 30rem;
  position: absolute;
}

.backgroundSeguros{
  width: 100%;
  height: 100%;
  background-color: #1CB1E2;
}

.cardsCredenciados{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
}

.badgecircle {
  width: 30px;
  height: 30px;
  background-color: #1CB1E2;
  border-radius: 50%; /* Torna o elemento circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
}

.badgeLine {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}

.liveVert{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.rotated-hr {
  width: 100px; /* Largura da linha horizontal */
  transform: rotate(90deg); /* Rotação de 45 graus */
  margin: 0; /* Remover a margem padrão da hr */
  border: none; /* Remover a borda padrão da hr */
  height: 2px; /* Altura da linha */
  background-color: black; /* Cor da linha */
}

.badgenumber {
  text-align: center;
}

body {
  -ms-overflow-style:none;
}

@media screen and (min-width: 999px) {

  .cardVantagens{
    display: flex;
    flex-direction: column;  
    align-items: center;
    text-align: center;
    width: 18rem;
    height: 18rem;
    background-color: #FFF;  
    border: solid 1px #969696;
    border-radius: 5px;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    padding: 1rem;
  }

  .cardVantagensTextTitle{
    font-size: 1.2rem;
    font-weight: 700;
    color: #1A4066;
  }

  .cardVantagensImage{
    width: 110;
    height: 110px;
    margin-top: 0px;
  }

  .AppBig {
    text-align: center;
    display: block;
    z-index: 999;
    margin-bottom: 2vh;
  }

  .AppSmall {
    text-align: center;
    height: 10vmin;
    display: none;
    z-index: 999;
  }

  .carrosselhome{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .carrossel{
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .Login{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    animation-name: loginComponents;
    animation-duration: 1s;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(11,40,66);
    z-index: 9999;

  }
  
  @keyframes loginComponents {
    0% {opacity: 0;}
    100% {opacity: 1;}
    
  }

  .btstep1{
    width: 100%;
    background-color: #29ade0;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
  }

  .btstep1:hover{
    background-color: #1c7daa;
  }

  .MenuSmall{
    display: none;
  }
  .MenuLarge{
    display: block;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: #FFF;
    width: 100%;
    height: 16vmin;
  
  }
  
  .logo {
    padding: 1rem;
  }
  
  .lineTriCollor{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 10px;
    padding: 0;
  }
  
  .collor1{
    width: 33.33%;
    height: 100%;
    background-color: #41B9BF;
  }
  
  .collor2{
    width: 33.33%;
    height: 100%;
    background-color: #1990CF;
  }
  
  .collor3{
    width: 33.33%;
    height: 100%;
    background-color: #50BD83;
  }

  .sidearLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #1990CF;
  }

  .bt-menu-adm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    background-color: #1990CF;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: white;
    transition: all 0.3s ease;
    text-decoration: none;
  }

  .bt-menu-adm:hover{
    background-color: #1c7daa;
  }
  
  .ligue{
    display: flex;
    flex-direction: row; 
  }
  
  .ligueIcon{
    padding: 1rem;
    width: 3rem;
  }

  .ajuda{
    width: 35%;
    position: fixed;
    bottom: 50px;
    right: 130px;
    color: white;
    font-size: 1.5vw;
    opacity: 0;
  }
  
  .bloco-ajuda{
    width: 0%;
    height: 50px;
    position: fixed;
    background-color: #1873dc;
    bottom: 40px;
    right: 45px;
    
  }
  
  .ligueText{
    padding: 1rem;
    margin-left: 2.5rem;
    font-size: 13px;
    color: #555555;
    width: 11rem;
    height: 100%;
    font-family: "open sans";
    text-align: start;  
  }
  
  .menu{
    display: flex;
    margin-top: -0.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    background-color: #fff;
    width: 100%; 
  }
  
  .space-menu{
    flex-grow: 1; 
  }

  .logo-image {
    flex-grow: 0; 
    margin-right: 10px;
  }
  
  .buttons{
    display: flex;
    justify-content: flex-end;
    width: 85%;
    height: 100%;
  }
  
  .buttonLink{
    font-family: "Poppins";
    margin-top: 1rem;
    margin-left: 1.5rem;
    color: #969696;
    font-size: 15px;
    transition: all 0.3s ease;
    cursor: pointer;    
  }
  
  .buttonLink:hover{
    color: #1990CF;
  }

  .buttonSeguro{
    font-family: "Poppins";
    color: #1A4066;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: #FFF;   
    border: solid 1px #1A4066;
    width: 100%;
    margin-top: 15px;
    font-size:12px;
    padding: 10px;
    
  }
  
  .buttonSeguro:hover{
    color: #1A4066;
    background-color: #64C7FF;
  }
  
  .slide{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: #F2F2F2;
  }
  
  .footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    line-height: 0.5;
    background-color: #1A4066;
    
    
  }

  .footer-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }

  .credenciadosmapa{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 75%;
  }

  .mapamt{
      width: 18rem;
      height: 18rem;    
  }

  .stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #999;
    background: #fff;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #999;
  }
  
  .circle.active {
    background: #fff;
    border-color: #29ade0;
    cursor: pointer;
    color: #000;
  }
  
  .line {
    height: 4px;
    width: 40px;
    background: #999;
    margin: -5px;
  }
  
  .line.active {
    background: #29ade0;
  }

  .listacredenciados{
      list-style: none;
      font-size: 1rem;
      font-weight: 400;
      color: #1A4066;
      font-family: "Poppins";
      margin-left: 2rem;
      margin-top: 1rem;
  }

  .btcomprarhome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  
  .step1bt{
    text-align: center; 
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center;
  }

  .footer-inf{
    display: flex;    
    justify-content: center;
    width: 100%;
    background-color: #1A4066;
  }

  .footer-info{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 100%;
    background-color: #D9D9D9;
    font-family: 'Poppins-Light';
    color: #969696;
    font-size: 0.9rem;
    line-height: 1.5;
    
  }

  td p{
    margin-top: -12px;
  }

  .table{
    margin-top: 2rem;
    
  }
  
  .table-footer{
    display: flex;
    justify-content: center;
    text-align: left;
    vertical-align: top;
    width: 100%;
    font-family: 'Poppins-Light';
    font-size: 0.8rem;
    color: #FFF;    
    margin-top: 2rem;
  }
  
  .title-table-footer{
    display: flex;
    justify-content: left;
    width: 100%;    
    font-family: 'Poppins-Bold';
    font-size: 0.8rem;
    color: #FFF;
    
  }
  
  .developed{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: #F2F2F2;
    height: 10vmin;
  }

  .descanso{
    font-family: 'Raleway', sans-serif;
    font-size: 2.2rem;
    font-weight: 500;
    color: #969696;
    font-family: "Poppins";
    margin-left: 10px;
  }

  .descanso2{
    font-family: 'Raleway', sans-serif;
    font-size: 2.2rem;
    font-weight: 900;
    color: #969696;
    font-family: "Poppins";
    margin-left: 10px;
  }

  .buttonHome{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 2.5rem;
    background-color: #1990CF;
    border-radius: 5rem;
    font-family: "Raleway";
    font-weight: 700;
    color: #FFF;
    font-size: 16px;
    border-color: #1990CF;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .buttonHome:hover{
    background-color: #2F749B;
    border-color: #2F749B;
  }

  .buttonSide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 2.5rem;
    background-color: #1990CF;
    font-family: "Raleway";
    font-weight: 700;
    color: #FFF;
    font-size: 14px;
    border-color: #1990CF;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .buttonSide:hover{
    background-color: #2F749B;
    border-color: #2F749B;
  }

  .buttonSideFilter{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    font-family: "Raleway";
    font-weight: 700;
    color: #000000;
    font-size: 14px;
    border-color: #ffffff;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .buttonSideFilter:hover{
    background-color: #2F749B;
    border-color: #2F749B;
  }

  .buttonLogout{
    color: #8a8a8a;
    cursor: pointer;
  }

  .buttonLogout:hover{
    transition: all 1s ease;
    color: #1990CF;
  } 

  @keyframes menuFilterOpen {
    0% {
      height: 0px;
    }
    100% {
      height: 100%;
    }
  }

  .menuFilter{
    animation-duration: 5s;
    animation: menuFilterOpen;
    
  }

  .menuFilter:hover{
    animation-duration: 5s;
    animation: menuFilterOpen;
  }

  .buttonHomeLarge{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2rem;
    background-color: #1990CF;
    border-radius: 5rem;
    font-family: "Arial";
    letter-spacing: 5px;
    font-weight: 500;
    color: #FFF;
    font-size: 12px;
    border-color: #1990CF;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .buttonEspecialidades{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4rem;
    background-color: #1990CF;
    border-radius: 5rem;
    font-family: "Arial";
    letter-spacing: 2px;
    font-weight: 500;
    color: #FFF;
    font-size: 12px;
    border-color: #1990CF;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: -5rem;
  }

  .cardsHome{    
    font-family: 'Open Sans', sans-serif;    
    justify-content: space-between;
    width: 95%;
    height: 230px;
    border: solid 2px #000; 
  }
}

.txt-especialidades{
  margin-top: 5rem;
  position: absolute;
  margin-left: 27%;
}

.table-especialidades{
  margin-top: 32rem;
  position: absolute;
  margin-left: 20%;
  padding: 1rem;
  width: 60%;
  height: 160%;
  background-color: #FFF;
  opacity: 0.1;
}

.table-especialidades-txt{
  margin-top: 28rem;
  position: absolute;
  margin-left: 20%;
  padding: 5rem;
  width: 60%;
  height: 100%;
  color: #FFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 1rem;
}

.txt-sobre-bg{
  margin-top: 2rem;
  position: absolute;
  margin-left: 20%;
  padding: 1rem;
  width: 60%;
  height: 160%;
  background-color: #FFF;

}

.txt-sobre-titulo{
  margin-top: 7rem;
  position: absolute;
  margin-left: 20%;
  width: 60%;
  height: 10%;
  color: #FFF;
  font-family: 'Montserrat-SemiBold', sans-serif;
  text-align: center;
  z-index: 1;
}

.txt-sobre{
  margin-top: 35rem;
  background-color: #1990CF;
  position: absolute;
  margin-left: 20%;
  padding: 2rem;
  width: 60%;
  height: 71%;
  color: #FFF;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.9;
  
}

.btn-contrate-agora{
  margin-top: 84rem;
  position: absolute;
  margin-left: 35%;
  padding: 1rem;
  width: 30%;
  height: 4.1rem;
  background-color: #4a8bdb;
  border-radius: 2rem;
  text-align: center;

}

.btn-contrate-agora:hover{
  background-color: #2F749B;
}

.assinaturaFoto{
  display: flex;
  justify-content: bottom;
  align-items: left;
  text-align: center;
}

.textAssinaturaA{
  font-family: "Poppins-Bold";
  font-size: 3rem;
  letter-spacing: 0.1rem;
  color: #969696;
  font-weight: 900;
}

.textAssinaturaB{
  font-family: "Poppins";
  font-size: 3rem;
  letter-spacing: 0.1rem;
  color: #969696;
}

.textAssinaturaItemTitulo{                
  font-family: "Poppins";
  font-size: 1.2rem;
  color: #1A4066;
  font-weight: 900;
  margin-left: 1rem;
}

.textAssinaturaItemDescricao{                
  font-family: "Poppins";
  font-size: 20px;
  color: #969696;
  margin-left: 1rem;    
}

.assinatura{
  margin-top: 6rem  ;
  margin-right: 18%;
  
}

.bgespecialidade{
  background-color: #2092d9;
  opacity: 0.8;
  background-blend-mode: multiply;
  background-size: cover; 
  background-position: center;
  height: 250vh;
  width: 100%;
  display: flex;
  justify-content: center;
    
}

.bgsobbre{
  background-color: #ffffff;
  background-size: cover; 
  background-position: center;
  height: 170vh;
  width: 100%;
  display: flex;
  justify-content: center;
    
}

.seja-credenciado-btn { 
  width: 12rem
}

.especialidade-hero-web { 
  display: block;
}

.especialidade-hero-app { 
  display: none;
}

.hero-app {
  display: none;
}

.hero-web {
  display: block;
}

.app {
  display: none !important;
}

.web {
  display: block;
}

.especialidade-hero-web-image { 
  text-align: "left";
  height: "70vh";
  vertical-align: "middle"
}

.modalSeguroContainer{
  display: flex;
  justify-content: space-between;
  height: 80vh;
}

.modalSeguroFoto{
  background-size: cover; 
  background-position: center; 
  width: 40%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.modalSeguroText{
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contrato{
  width: 800px, 
}

.texto-grande{
  font-family: "Inter", sans-serif;
  margin-top: -30px;
  font-size: 80px;
  line-height: 1;
  font-weight: 900;
}

.texto-pequeno{
  font-size: 16px;
  
}

.texto_sup_video{
  color: #fff;
  font-family: "Poppins-Bold";
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.texto_inf_video{
  color: #fff;
  font-family: "Poppins-Bold";
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.texto-franqueado-2{
  color: #969696;
  font-family: "Poppins-Bold";
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.texto-franqueado-3{
  color: #969696;
  font-family: "Poppins";
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 30px;
}

.tabelas-1-card{
  display: flex;
  flex-direction: column;
  background-color: #F1F1F1;
  width: 420px;
  border-radius: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  color: #626262;
  font-family: "Poppins";
  margin: 20px;   
}

.titulo-tabelas{
  font-family: "Poppins-Bold";
  margin-top: -30px;
  padding: 5px;
  background-color: #1CB1E2;
  border-radius: 18px;
  width: 200px;
  height: 40px;
  font-size: 22px;
  color: #fff;
  margin-bottom: 10px;
}

.tabelas-texto{
  font-size: 12px;
  color: #969696;
  margin-bottom: 10px;
}

.tabelas-1-card table {
  border-collapse: collapse;
  width: 100%;
  color: #626262;
}

.tabelas-1-card th, td {
  border: 1px solid transparent; /* Definindo a borda como transparente por padrão */
  padding: 8px;
  text-align: center;
}

/* Cor da borda apenas para a linha que divide as colunas */
.tabelas-1-card th {
  border-right: 1px solid #969696;
}

/* Cor da borda inferior de cada célula */
.tabelas-1-card td {
  border-bottom: 1px solid #969696;
}

/* Estilo especial para a borda inferior da última linha */
.tabelas-1-card tr:last-child td {
  border-bottom: none; /* Removendo a borda inferior da última linha */
}

.tabelas-texto-2{
  margin-top: 5px;
  font-size: 12px;
}

.tabelas-texto-3{
  font-family: "Poppins-Bold";
  font-size: 22px;
  color: #4E4E4E;
}

.coluna-esquerda {
  border-right: 1px solid #969696; /* Defina a cor desejada para a borda direita da coluna esquerda */
}

.tabelas-1{
  width: 100%;
}

.tabelas-1-centro{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.mail-franqueado{
  background-color: #1A4066;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.mail-franqueado-txt-1{
  font-family: "Poppins-Bold";
  font-size: 45px;
}

.mail-franqueado-txt-2{
  font-size: 20px;
}

.mail-franqueado-form{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.input-form{
  width: 300px;
  margin: 10px;
}

.mail-franqueado-card{
  width: 700px;
  padding: 20px;
  border-radius: 20px;
  background-color: #F1F1F1;
  margin: 30px;  
}

.mail-franqueado-card-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button-form{
  margin-top: 10px  ;
  background-color: #1CB1E2;
  color: #fff;
  border: none;
  width: 250px;
}

.text-contato-franqueado h1{
  font-family: "Poppins-Bold";
  font-size: 50px;
  color: #1A4066;
}

.text-contato-franqueado h5{
  font-family: "Poppins-Bold";
  font-size: 20px;
  color: #1A4066;
  margin-bottom: -5px;
}

.text-contato-franqueado{
width: 600px;
justify-content: center;
align-items: center;
text-align: center;
padding: 20px;
}
.container-contato-franqueado{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.pagination-controls {
  margin-top: 20px;
  text-align: center;
}

.pagination-controls button,
.pagination-controls select {
  padding: 8px 15px;
  margin: 0 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(28, 177, 226);
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls select {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 5px;
}
